<template>
  <div class="notice_info">
    <!-- 公告详情 -->
    <div class="notice_title">{{notice.title}}</div>
    <div class="notice_time">{{send_date}}</div>
    <div class="notice_content" >
        <div class="notice_summary">摘要:{{notice.summary}}</div>
        <div class="notice_data" v-html="notice.data"></div>
    </div>
  </div>  
</template>

<script>
import { Toast } from 'vant';
import { getNotificationInfo }  from '@/api/api';
import {
    mapState
} from 'vuex'
import { resolveDateStr } from '@/utils/utils.js'
export default {
  data(){
    return {
        notice:{
            title:'',
            data:'',
            send_date:''
        },
        id:''
    }
  },
    computed: {
        ...mapState(['noticeInfo']),
        send_date(){
            let date=resolveDateStr(this.notice.send_date)
            return date
        }
    },
  created() {
      this.init()
  },
  methods: {
      async init(){
        this.id=this.$route.query.id?this.$route.query.id:this.noticeInfo.id; //接收来自链接的参数
        await getNotificationInfo({
            params:{
                id:this.id
            }
        }).then(res=>{
            console.log('公告详情',res)
            this.notice=res
        }).catch(err=>{
            Toast(err.response.data.message)
        })
      }
    // 获取公告详情
  }
}
</script>

<style lang="scss" scoped>
.notice_info{
    padding:15px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    .notice_title{
        font-size:22px;
        font-weight: bold;
    }
    .notice_time{
        font-size:14px;
        color: rgb(123, 123, 123);
        padding: 10px 0;
    }
    .notice_content{
        font-size:16px;
        padding-bottom: 100px;
        .notice_summary{
            color: rgb(123, 123, 123);
            font-style: italic;
        }
    }
}
</style>